import ContactUs from "./Contact";
import imgDung1 from "../../src/images/dung-ly-1.webp";
import imgDung2 from "../../src/images/dung-ly-letter-2.webp";
import imgDung3 from "../../src/images/dung-ly-2.webp";
import imgDung4 from "../../src/images/dung-ly-letter-1.webp";

const EndSlide = () => {
  return (
    <div className="slide-end container" id="end-slide">
      <div className="content">
        <h2>After the Rescue</h2>
        <p>
          Following his rescue, my father, Van An Nguyen, began working at a
          refugee camp in Hong Kong. Eventually, he was resettled in Denmark
          because his sister lived there. Two years later, in 1990, he was reunited with my mother and me.
        </p>
        <h3>Seeking a Long-Lost Friend</h3>
        <p>
          During his escape from Vietnam in 1988, my father established a deep friendship with
          Dung Ly among the boat people. Sadly, they lost touch  in the &apos;90s, and now, my father hopes that by sharing his story today, he can
          reconnect with his cherished friend. The last correspondence he received from Dung was after his relocation to Texas, USA, where he mentioned having a sister named Nga. Below, we
          are some photographs from their last exchanges. If
          you have any information that might be helpful, please don&apos;t hesitate to reach out to us via <a href="mailto:vietrefugee@gmail.com">email</a> or by sharing this story.
        </p>
        <figure className="media">
          <img src={imgDung1} alt="" />
          <img src={imgDung2} alt="" />
          <img src={imgDung3} alt="" />
          <img src={imgDung4} alt="" />
          <figcaption>
            Photos of Dung Ly with handwritten notes from 1988 and 1989.
          </figcaption>
        </figure>
      </div>
      <ContactUs />
      <div className="copyright">
        <p>© 2024 VietRefugee.com</p>
      </div>
    </div>
  );
};

export default EndSlide;
