const IntroSlide = () => {
  return (
    <div className="slide-intro container xtra-dark" id="slide-1">
      <header>
        <h1>1988: a Vietnamese Boat Refugee Story</h1>
        <div className="slide-intro__info">
          <p>
            This is the story of my father&apos;s escape from post-war Vietnam. Through the retelling of his courageous story,  my father hopes to reunite with his dear friend, Dung Ly, who, like him, braved the difficult journey as one of the boat people.
          </p>
        </div>
        <div className="scroll-icon">
          <span>Scroll Down</span>
          <svg
            id="scroll-down"
            width="10"
            height="28"
            viewBox="0 0 16 28"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none">
              <path
                id="scroll-down-line"
                d="M7.99999 34L15.7942 24.25H0.205765L7.99999 34Z"
                fill="#ffffff"
              />
              <path
                id="scroll-down-arrow"
                d="M6.99999 0H8.99999V31H6.99999V0Z"
                fill="#ffffff"
              />
            </g>
          </svg>
        </div>
      </header>
    </div>
  );
};

export default IntroSlide;
